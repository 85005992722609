import { AfterViewInit, Directive, ElementRef, HostListener, inject, Input } from '@angular/core';
import { MixpanelService, MixpanelTrackingType } from './mixpanel.service';

@Directive({
  selector: '[mmMixpanelTrack]',
  standalone: true,
})
export class MixpanelTrackDirective implements AfterViewInit {
  private mixpanel = inject(MixpanelService);
  private data: Record<string, unknown> = {};
  private eventName = '';
  private trackingType: MixpanelTrackingType = 'default';
  private trackingEnabled = true;

  private element: ElementRef<HTMLElement> = inject(ElementRef);

  private alreadyEnabled = false;

  ngAfterViewInit(): void {
    if (this.trackingEnabled && !this.alreadyEnabled) {
      if (this.trackingType === 'link' || this.trackingType === 'form') {
        this.mixpanel.track({
          eventName: this.eventName,
          data: this.data ?? {},
          trackingType: this.trackingType,
          elementQueryId: this.element.nativeElement,
        });
        this.alreadyEnabled = true;
      }
    }
  }

  @Input()
  set mixpanelData(data: Record<string, unknown>) {
    this.data = data;
  }

  @Input()
  set mmMixpanelTrack(eventName: string) {
    this.eventName = eventName;
  }

  @Input()
  set mixpanelTrackingType(trackingType: MixpanelTrackingType) {
    this.trackingType = trackingType;
  }

  @Input()
  set mixpanelTrackingEnabled(trackingEnabled: boolean) {
    this.trackingEnabled = trackingEnabled;
  }

  @HostListener('click')
  onClick(): void {
    if (this.trackingType === 'default' && this.trackingEnabled) {
      this.mixpanel.track({
        eventName: this.eventName,
        data: this.data ?? {},
      });
    }
  }
}
