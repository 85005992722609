import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';
import { AppDisplay, favicon, logo, mappingMovinmotionApplicationToAppDisplay, titlePrefix } from './app-display.model';
import { combineLatest, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { MovinmotionApplication } from '../../shared/models/environment';
import constants from '../constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AppDisplayService {
  private static readonly filmFranceUrlChecker = new RegExp(/^\/film-france(-talents)?\//);

  private appHostToDisplay: {
    [host: string]: AppDisplay;
  } = this.computeMovinmotionApplicationHosts(environment.movinmotionAppUrls);

  private display$: Observable<AppDisplay> = combineLatest([
    this.route.queryParamMap,
    this.auth.redirectUrlChanged$(),
  ]).pipe(
    map(([params, redirectUrl]) => {
      if (params.has(environment.queryParamsKeys.display)) {
        const display = params.get(environment.queryParamsKeys.display);
        if (display && Object.values(AppDisplay).includes(display as any)) {
          return display as AppDisplay;
        }
      }
      if (environment.displayByAppHostEnabled) {
        if (redirectUrl?.pathname?.match(AppDisplayService.filmFranceUrlChecker)) {
          return AppDisplay.filmFranceTalents;
        }
        if (this.appHostToDisplay?.[redirectUrl?.host]) {
          return this.appHostToDisplay[redirectUrl.host];
        }
      }
      return AppDisplay.core;
    }),
    shareReplay(1),
  );

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) {
    // Automatically update the favicon depending on the app display
    this.getDisplay$()
      .pipe(
        untilDestroyed(this),
        map(display => favicon[display] ?? favicon[AppDisplay.core]),
      )
      .subscribe(faviconUrl => {
        const faviconElement: HTMLLinkElement = document.querySelector('#favicon');
        faviconElement.href = faviconUrl;
      });
  }

  setTitle$(suffix$: Observable<string>, withoutPrefix = false): Observable<void> {
    return combineLatest([suffix$, this.display$]).pipe(
      map(([suffix, display]) => {
        this.title.setTitle((withoutPrefix ? '' : titlePrefix[display] + ' - ') + suffix);
      }),
    );
  }

  getMainLogo$(): Observable<string> {
    return this.display$.pipe(map(display => logo[display]));
  }

  getDisplay$(): Observable<AppDisplay> {
    return this.display$;
  }

  getSupportEmail$(): Observable<string> {
    return this.getDisplay$().pipe(
      map(appDisplay => {
        return appDisplay === AppDisplay.worker ? constants.supportEmail.worker : constants.supportEmail.general;
      }),
    );
  }

  private computeMovinmotionApplicationHosts(apps: { [key in MovinmotionApplication]: string }): {
    [host: string]: AppDisplay;
  } {
    const result = {};
    for (const [key, value] of Object.entries(apps)) {
      try {
        const url = new URL(value);
        result[url.host] = mappingMovinmotionApplicationToAppDisplay[key];
      } catch (err) {
        // Noop
      }
    }
    return result;
  }
}
