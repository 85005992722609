<h4 class="mat-body-4">{{ 'mm.shared.tooltip-advertising.title' | translate }}</h4>
<mm-tooltip-card [theme]="tooltipTheme" title="" icon="">
  <div [innerHtml]="'mm.shared.tooltip-advertising.text' | translate"></div>
  <div class="tooltip-icons-container">
    <mat-icon svgIcon="mm-service-social" [title]="'mm.common.applications.social' | translate"></mat-icon>
    <mat-icon svgIcon="mm-service-accounting" [title]="'mm.common.applications.accounting' | translate"></mat-icon>
    <mat-icon svgIcon="mm-service-talents" [title]="'mm.common.applications.talents' | translate"></mat-icon>
    <mat-icon svgIcon="mm-service-worker" [title]="'mm.common.applications.worker' | translate"></mat-icon>
  </div>
  <a
    [mmMixpanelTrack]="'Souscrire'"
    [mixpanelTrackingType]="'link'"
    [mixpanelTrackingEnabled]="currentAppAllowed()"
    [href]="subscribeUrl"
    class="subscribe"
    mat-stroked-button
  >
    {{ 'mm.shared.tooltip-advertising.button.subscribe' | translate }}
  </a>
</mm-tooltip-card>
