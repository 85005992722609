import { Component, OnInit, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AppDisplayService } from '../../core/services/app-display.service';
import { AuthService } from '../../core/services/auth.service';
import { delay, of } from 'rxjs';

const REDIRECTION_DELAY_IN_SECONDS = 10;

@UntilDestroy()
@Component({
  selector: 'mm-page-password-changed',
  templateUrl: './page-password-changed.component.html',
  styleUrls: ['./page-password-changed.component.scss'],
})
export class PagePasswordChangedComponent implements OnInit {
  private auth = inject(AuthService);
  private translate = inject(TranslateService);
  private appDisplayService = inject(AppDisplayService);

  /**
   * Flag that indicates if a redirection to process origin page is already in progress
   */
  private isRedirectingToProcessOrigin = false;

  ngOnInit(): void {
    this.appDisplayService
      .setTitle$(this.translate.stream(_('mm.pages.password-changed.title.head')))
      .pipe(untilDestroyed(this))
      .subscribe();

    of(null)
      .pipe(untilDestroyed(this), delay(REDIRECTION_DELAY_IN_SECONDS * 1000))
      .subscribe(() => {
        this.navigateToProcessOrigin();
      });
  }

  /**
   * Navigate to process origin page (= provided redirect URL)
   */
  navigateToProcessOrigin(): void {
    if (!this.isRedirectingToProcessOrigin) {
      this.isRedirectingToProcessOrigin = true;
      this.auth.goToRedirectUrl$().pipe(untilDestroyed(this)).subscribe();
    }
  }
}
