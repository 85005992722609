import { MovinmotionApplication } from '../../shared/models/environment';

export enum AppDisplay {
  core = 'core',
  accounting = 'accounting',
  social = 'social',
  worker = 'worker',
  talents = 'talents',
  filmFranceTalents = 'fft',
  odalie = 'odalie',
}

export const movinMotionApp: { [key in AppDisplay]: string } = {
  [AppDisplay.core]: 'CORE',
  [AppDisplay.accounting]: 'COMPTA',
  [AppDisplay.social]: 'SOCIAL',
  [AppDisplay.worker]: 'WORKER',
  [AppDisplay.talents]: 'TALENTS',
  [AppDisplay.filmFranceTalents]: 'TALENTS',
  [AppDisplay.odalie]: 'CORE',
};

export const titlePrefix: { [key in AppDisplay]: string } = {
  [AppDisplay.core]: 'Movinmotion',
  [AppDisplay.accounting]: 'Movinmotion Compta',
  [AppDisplay.social]: 'Movinmotion Social',
  [AppDisplay.worker]: 'Movinmotion Salarié',
  [AppDisplay.talents]: 'Movinmotion Talents',
  [AppDisplay.filmFranceTalents]: 'Film France Talents',
  [AppDisplay.odalie]: 'Odalie 2 CMB',
};

export const logo: { [key in AppDisplay]: string } = {
  [AppDisplay.core]: '/assets/img/mm-logo-core.svg',
  [AppDisplay.accounting]: '/assets/img/mm-logo-accounting.svg',
  [AppDisplay.social]: '/assets/img/mm-logo-social.svg',
  [AppDisplay.worker]: '/assets/img/mm-logo-worker.svg',
  [AppDisplay.talents]: '/assets/img/mm-logo-talents.svg',
  [AppDisplay.filmFranceTalents]: '/assets/img/ff-logo-talents.svg',
  [AppDisplay.odalie]: '/assets/img/logo_thalie.png',
};

export const favicon: { [key in AppDisplay]: string } = {
  [AppDisplay.core]: '/assets/img/favicons/favicon-core.png',
  [AppDisplay.accounting]: '/assets/img/favicons/favicon-accounting.png',
  [AppDisplay.social]: '/assets/img/favicons/favicon-social.png',
  [AppDisplay.worker]: '/assets/img/favicons/favicon-worker.png',
  [AppDisplay.talents]: '/assets/img/favicons/favicon-talents.png',
  [AppDisplay.filmFranceTalents]: '/assets/img/favicons/favicon-fft.png',
  [AppDisplay.odalie]: '/assets/img/favicons/favicon-social.png',
};

export const mappingMovinmotionApplicationToAppDisplay: { [key in MovinmotionApplication]: AppDisplay } = {
  [MovinmotionApplication.core]: AppDisplay.core,
  [MovinmotionApplication.accounting]: AppDisplay.accounting,
  [MovinmotionApplication.social]: AppDisplay.social,
  [MovinmotionApplication.worker]: AppDisplay.worker,
  [MovinmotionApplication.talents]: AppDisplay.talents,
  [MovinmotionApplication.odalie]: AppDisplay.odalie,
};
