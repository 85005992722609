import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Purge object from null values and empty objects
 *
 * @param originalObject with potential null values or empty object as value
 */
export function purgeObject<T extends object>(originalObject: T): Partial<T> {
  Object.keys(originalObject).forEach(key => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const elem = originalObject[key];
    if (
      elem === null ||
      elem === undefined ||
      (Array.isArray(elem) && elem.length === 0) ||
      (typeof elem === 'object' && Object.values(elem).filter(v => v !== null).length === 0) ||
      elem === ''
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete originalObject[key];
    }
  });

  return originalObject;
}

export const isFunction = (fn: unknown) => typeof fn === 'function';

export function isSameObj<T>(obj1: T, obj2: T): boolean {
  try {
    return JSON.stringify(obj1).split('').sort().join('') === JSON.stringify(obj2).split('').sort().join('');
  } catch (_) {
    return false;
  }
}

export function distinctUntilObjChanged<T>() {
  return distinctUntilChanged<T>(isSameObj);
}
