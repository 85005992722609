import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ENVIRONMENT_CONFIG } from '@movinmotion/conf-shared';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  private environment = inject(ENVIRONMENT_CONFIG);
  private readonly CORE_BASE_URL = `${this.environment.api.movinmotionBackend}/public/user/password`;
  private http = inject(HttpClient);
  private translate = inject(TranslateService);
  changePassword$(passwordParams: PasswordChangeParams): Observable<string> {
    return this.http.post(
      `${this.CORE_BASE_URL}/change`,
      {
        ...passwordParams,
        language: this.translate.currentLang,
      },
      {
        responseType: 'text',
      },
    );
  }

  otpRequest$(passwordParams: OtpRequestParam): Observable<void> {
    return this.http.post<void>(`${this.CORE_BASE_URL}/request`, {
      ...passwordParams,
      language: this.translate.currentLang,
    });
  }

  otpValidation$(passwordParams: ValidateOtpRequestParam): Observable<{ secret: string }> {
    return this.http.post<{ secret: string }>(`${this.CORE_BASE_URL}/validate`, {
      ...passwordParams,
      language: this.translate.currentLang,
    });
  }

  generateSignInMail$(signInEmailParam: SignInEmailParam): Observable<void> {
    return this.http.post<void>(`${this.CORE_BASE_URL}/sign-in`, {
      ...signInEmailParam,
      language: this.translate.currentLang,
    });
  }
}

export interface PasswordChangeParams {
  application: string;
  email: string;
  entropy: number;
  password: string;
  otp: string;
  offset: string;
}

export interface OtpRequestParam {
  application: string;
  email: string;
}

export interface ValidateOtpRequestParam {
  application: string;
  otp: string;
  email: string;
}

export interface SignInEmailParam {
  application: string;
  email: string;
  offset: string;
  redirectUrl: string;
}
