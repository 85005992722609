import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

const numberRegExp = /\d/;
const alphaRegExp = /[a-zA-Z]/;

export class StringValidators {
  static readonly invalidMoreThanEightChars = 'invalid-more-than-eight-chars';
  static readonly invalidContainsNumber = 'invalid-contains-number';
  static readonly invalidContainsAlpha = 'invalid-contains-alpha';

  /**
   * Check if the password provided is valid regarding Movinmotion specifications.
   */
  static passwordValidator = Validators.compose([
    StringValidators.moreThanEightCharsValidator,
    StringValidators.containsNumberValidator,
    StringValidators.containsAlphaValidator,
  ]);

  /**
   * Check if the string length is higher than 8 char
   */
  static isMoreThanEightChars(str: string): boolean {
    if (!str) {
      return false;
    }
    return str.length >= 8;
  }

  /**
   * The {@see isMoreThanEightChars} function as an Angular Form Validator
   */
  static moreThanEightCharsValidator(control: AbstractControl): ValidationErrors | null {
    if (!control?.value) {
      return null;
    }
    return StringValidators.isMoreThanEightChars(control.value)
      ? null
      : { [StringValidators.invalidMoreThanEightChars]: { value: control.value } };
  }

  /**
   * Check if the string has at least a number
   */
  static containsNumber(str: string): boolean {
    if (!str) {
      return false;
    }
    return numberRegExp.test(str);
  }

  /**
   * The {@see containsNumber} function as an Angular Form Validator
   */
  static containsNumberValidator(control: AbstractControl): ValidationErrors | null {
    if (!control?.value) {
      return null;
    }
    return StringValidators.containsNumber(control.value)
      ? null
      : { [StringValidators.invalidContainsNumber]: { value: control.value } };
  }

  /**
   * check if the string has at least an alphanumeric
   */
  static containsAlpha(str: string): boolean {
    if (!str) {
      return false;
    }
    return alphaRegExp.test(str);
  }

  /**
   * The {@see containsAlpha} function as an Angular Form Validator
   */
  static containsAlphaValidator(control: AbstractControl): ValidationErrors | null {
    if (!control?.value) {
      return null;
    }
    return StringValidators.containsAlpha(control.value)
      ? null
      : { [StringValidators.invalidContainsAlpha]: { value: control.value } };
  }
}
