<mm-layout-split [loading]="globalLoading" [disclaimer]="true">
  <form class="login-container" [formGroup]="form" (ngSubmit)="submit(currentConnexionMode)">
    <h4 class="mat-body-4">
      {{ 'mm.pages.login.title.connection' | translate }}
    </h4>

    <ng-container [ngSwitch]="currentConnexionMode">
      <ng-container *ngSwitchCase="ConnexionMode.classical">
        <ng-container *ngTemplateOutlet="classicalConnexion"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="ConnexionMode.magicLink">
        <ng-container *ngTemplateOutlet="magicLinkConnexion"></ng-container>
      </ng-container>
    </ng-container>

    <div class="help-container">
      <p>
        {{ 'mm.pages.login.help.needHelp' | translate }}<br />
        <a
          [mmMixpanelTrack]="'Consultez cet article'"
          [mixpanelTrackingType]="'link'"
          [mixpanelTrackingEnabled]="currentAppAllowed()"
          href="https://www.movinmotion.com/blog/non-classe/votre-connexion-tout-pour-bien-vous-re-connecter/"
          >{{ 'mm.pages.login.help.checkArticle' | translate }}
        </a>
        {{ 'mm.pages.login.help.or' | translate }}
        <a
          [mmMixpanelTrack]="'Contactez-nous'"
          [mixpanelTrackingType]="'link'"
          [mixpanelTrackingEnabled]="currentAppAllowed()"
          href="mailto:{{ appDisplayService.getSupportEmail$() | async }}"
          >{{ 'mm.pages.login.help.contactUs' | translate }}</a
        >
      </p>
    </div>

    <ng-container *ngIf="appDisplayService.getDisplay$() | async as display">
      <div class="odalie-container" *ngIf="display === availableAppDisplay.odalie">
        <a mat-raised-button color="primary" class="mm-compact" [href]="odalieSubscribeLink">
          {{ 'mm.pages.login.button.odalieSubscribe' | translate }}
        </a>
      </div>
    </ng-container>

    <ng-template #classicalConnexion>
      <mm-tooltip-card icon="mm-shield" [iconFluff]="false" *ngIf="movinmotionAuthenticationError">
        <div>{{ 'mm.common.errors.movinmotionAuthentication' | translate }}</div>
      </mm-tooltip-card>

      <mm-custom-input
        type="email"
        [small]="true"
        [label]="'mm.pages.login.email.label' | translate"
        [placeholder]="'mm.pages.login.email.placeholder' | translate"
        [formControlName]="formFieldKeys.email"
        [errors]="emailErrors"
      ></mm-custom-input>
      <mm-custom-input
        type="password"
        [small]="true"
        [label]="'mm.pages.login.password.label' | translate"
        [placeholder]="'mm.pages.login.password.placeholder' | translate"
        [formControlName]="formFieldKeys.password"
        [errors]="passwordErrors"
        [hide]="true"
      >
        <mm-caps-num-lock-component
          CustomInputComponentWarnMessage
          [capsLockMessage]="'mm.shared.caps-num-lock.caps-lock' | translate"
          [allLockMessage]="'mm.shared.caps-num-lock.caps-and-num-lock' | translate"
          [numLockMessage]="'mm.shared.caps-num-lock.num-lock' | translate"
        ></mm-caps-num-lock-component>
      </mm-custom-input>

      <div class="errors-card">
        <mm-tooltip-card icon="mm-shield" [iconFluff]="false" *ngIf="form.errors && (form?.dirty || form?.touched)">
          <ng-container *ngFor="let error of loginErrors">
            <div *ngIf="error.isError(form)">{{ error.text$ | async }}</div>
          </ng-container>
        </mm-tooltip-card>
      </div>

      <div class="buttons-container">
        <button
          mat-stroked-button
          type="button"
          (click)="goToPasswordResetPage(false, true)"
          color="accent"
          class="mm-compact mm-borderless forgot-password-button"
        >
          {{ 'mm.pages.login.button.forgotPassword' | translate }}
        </button>
        <button
          mat-stroked-button
          class="connection-button"
          [disabled]="!form.valid || form.pristine || submitLoading"
          color="primary"
          type="submit"
        >
          {{ 'mm.pages.login.button.connection' | translate }}
          <mat-spinner [diameter]="25" *ngIf="submitLoading"></mat-spinner>
        </button>
      </div>

      <div class="magic-link">
        <div class="separator-magik-link">
          {{ 'mm.pages.login.magicLink.separator' | translate }}
        </div>
        <button mat-stroked-button color="primary" class="magic-link-button light" (click)="magicLinkConnexionView()">
          <mat-icon>mail</mat-icon>
          {{ 'mm.pages.login.button.magicLink.connexion' | translate }}
        </button>
      </div>
    </ng-template>

    <ng-template #magicLinkConnexion>
      <div class="magic-link">
        <span [innerHTML]="'mm.pages.login.magicLink.subtitle' | translate"></span>
        <div class="errors-card" *ngIf="haveOneError.bind(this) | wrapFn: form">
          <mm-tooltip-card icon="mm-danger-plain" [iconFluff]="false" *ngIf="form.errors">
            <ng-container *ngFor="let error of loginErrors">
              <div *ngIf="error.isError(form)" [innerHTML]="error.text$ | async"></div>
            </ng-container>
          </mm-tooltip-card>
        </div>
        <mm-custom-input
          type="email"
          [small]="true"
          [label]="'mm.pages.login.email.label' | translate"
          [placeholder]="'mm.pages.login.email.placeholder' | translate"
          [formControlName]="formFieldKeys.email"
          [errors]="emailErrors"
        ></mm-custom-input>
        <div class="magic-link-send">
          <button
            mat-stroked-button
            color="primary"
            type="submit"
            [disabled]="form.get(formFieldKeys.email).invalid || magicLinkdisabled"
          >
            {{ 'mm.pages.login.button.sendMagicLink' | translate }}
          </button>
        </div>
        <div class="success-send-magic-link" *ngIf="magicLinkSended">
          <span class="success-send-magic-link-icon-wrapper"
            ><mat-icon color="success" class="success-send-magic-link-icon">check_circle</mat-icon></span
          >
          <div class="success-send-magic-link-header"
            >{{ 'mm.pages.login.button.magicLink.success.header' | translate }}
          </div>
          <div class="success-send-magic-link-content"
            >{{ 'mm.pages.login.button.magicLink.success.content' | translate }}
          </div>
        </div>

        <div class="separator-magik-link">
          {{ 'mm.pages.login.magicLink.separator' | translate }}
        </div>
        <button mat-stroked-button color="primary" class="magic-link-button light" (click)="classicalConexionView()">
          <mat-icon>key</mat-icon>
          {{ 'mm.pages.login.button.magicLink.connexionView' | translate }}
        </button>
      </div>
    </ng-template>
  </form>
</mm-layout-split>
