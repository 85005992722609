<mm-layout-card>
  <form class="reset-password-container" [formGroup]="form" (ngSubmit)="submit()">
    <h4 class="title mat-body-4">
      {{ 'mm.pages.password-reset.title.resetPassword' | translate }}
    </h4>

    <p class="subtitle mat-body">
      {{ 'mm.pages.password-reset.subtitle.1' | translate }}
      <span class="subtitle-highlight">{{ 'mm.pages.password-reset.subtitle.2' | translate }}</span>
      {{ 'mm.pages.password-reset.subtitle.3' | translate }}
    </p>

    <mm-custom-input
      type="email"
      [small]="true"
      [label]="'mm.pages.password-reset.email.label' | translate"
      [placeholder]="'mm.pages.password-reset.email.placeholder' | translate"
      [formControlName]="formFieldKeys.email"
      [errors]="emailErrors"
    ></mm-custom-input>

    <div class="buttons-container">
      <button
        mat-stroked-button
        type="button"
        (click)="goBackToLoginPage()"
        color="accent"
        class="mm-compact mm-borderless back-to-login-button"
      >
        {{ 'mm.pages.password-reset.button.goBack' | translate }}
      </button>
      <button
        mat-stroked-button
        class="send-button"
        [disabled]="!form.valid || form.pristine || submitLoading || hasSentForgotPasswordMail"
        color="primary"
        type="submit"
      >
        {{ 'mm.pages.password-reset.button.send' | translate }}
        <mat-spinner [diameter]="25" *ngIf="submitLoading"></mat-spinner>
      </button>
    </div>
  </form>
</mm-layout-card>
