import { APP_INITIALIZER, ErrorHandler, Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateInitializerFactory, TranslateModule } from '../shared/translate.module';
import { TranslateService } from '@ngx-translate/core';
import { LoggerModule, NGXLogger } from 'ngx-logger';
import { environment } from '../../environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, inMemoryPersistence, provideAuth } from '@angular/fire/auth';
import { setPersistence } from 'firebase/auth';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { provideEnvironment } from './providers/environment.provider';
import { MixpanelService } from '@movinmotion/data-access-third-party';
import { getAppConfigProvider } from '@movinmotion/conf-shared';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import pkg from '../../../../../package.json';

@NgModule({
  declarations: [],
  imports: [
    // Translation
    TranslateModule.forRoot(),

    // Angular Fire
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useFirebaseEmulator) {
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
      }
      // This method is imported directly from firebase because of this issue https://github.com/angular/angularfire/issues/3131
      setPersistence(auth, inMemoryPersistence).catch(err => {
        console.error('Error while setting persistence', err);
      });
      return auth;
    }),

    // Logger
    LoggerModule.forRoot(environment.logger),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: TranslateInitializerFactory,
      deps: [TranslateService, NGXLogger, Injector, Sentry.TraceService],
      multi: true,
    },
    provideEnvironment(),
    getAppConfigProvider({
      appId: 'AUTH',
      version: pkg.version,
    }),
    MixpanelService,
  ],
})
export class CoreModule {
  /**
   * Check that this core module is call only once, and initialise some services
   */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
