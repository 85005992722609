<ng-container *ngIf="appDisplay.getDisplay$() | async; else loadingTmp">
  <div class="layout-card" ngClass.lt-lg="mobile" *ngIf="!loading; else loadingTmp">
    <div class="mm-card" fxLayout="column" fxLayoutGap="20px">
      <div class="header">
        <img class="mm-logo" [src]="appDisplay.getMainLogo$() | async" alt="mm-logo-desktop" />
      </div>

      <div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loadingTmp>
  <mm-loading></mm-loading>
</ng-template>
