import { Component, Input, inject } from '@angular/core';
import { AppDisplayService } from '../../../core/services/app-display.service';

@Component({
  selector: 'mm-layout-card',
  templateUrl: './layout-card.component.html',
  styleUrls: ['./layout-card.component.scss'],
})
export class LayoutCardComponent {
  @Input() loading = false;

  public appDisplay = inject(AppDisplayService);
}
