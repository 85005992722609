<div class="mm-input-container" [ngClass]="{ disabled: disabled }">
  <mat-form-field class="mm-input-form" appearance="outline">
    <input
      matInput
      class="mm-input-field"
      [id]="id"
      [name]="name"
      [placeholder]="placeholder"
      [value]="value"
      [disabled]="disabled"
      (input)="input($event)"
      (focus)="onFocus()"
      [attr.maxlength]="otpLength"
      type="numeric"
      style="--otpLength: {{ otpLength }}"
    />
  </mat-form-field>

  <ng-container *ngFor="let error of errors">
    <mat-error
      class="mm-input-error"
      *ngIf="error.isError(getControl()) && (getControl()?.dirty || getControl()?.touched)"
    >
      {{ error.text$ | async }}
    </mat-error>
  </ng-container>
</div>
