import sha1 from 'crypto-js/sha1';
import encHex from 'crypto-js/enc-hex';
import { AbstractControl, ValidationErrors } from '@angular/forms';

const SsoDomainHash: string[] = [
  //'998ffd6ec7693354cc3d15d4b9d3314dec669315',
  'fad718bb43134c3dcfb312ed088a3ab2efbd7b70',
  '0eab0fec2ad00f1b753c21eb638c92864a2620c1',
];
function isUsingSso(email: string): boolean {
  const emailSuffix = email.split('@')[1];

  return emailSuffix && SsoDomainHash.includes(encHex.stringify(sha1(emailSuffix)));
}

export function ssoEmailValidator(control: AbstractControl): ValidationErrors | null {
  if (isUsingSso(control.value)) {
    return { 'unavailable-domain': true };
  }
  return null;
}
