<div class="layout" ngClass.lt-lg="mobile" *ngIf="!loading; else loadingTmp">
  <img class="mm-logo" src="/assets/img/mm-logo-core.svg" alt="mm-core-logo-desktop" />
  <mm-tooltip-card [theme]="tooltipTheme">
    <div>{{ 'mm.pages.select-app.question' | translate }}</div>
    <div class="tooltip-icons-container">
      <button mat-flat-button (click)="select(apps.social)" [title]="'mm.common.applications.social' | translate">
        <mat-icon svgIcon="mm-service-social"></mat-icon>
      </button>
      <button
        mat-flat-button
        (click)="select(apps.accounting)"
        [title]="'mm.common.applications.accounting' | translate"
      >
        <mat-icon svgIcon="mm-service-accounting"></mat-icon>
      </button>
      <button mat-flat-button (click)="select(apps.talents)" [title]="'mm.common.applications.talents' | translate">
        <mat-icon svgIcon="mm-service-talents"></mat-icon>
      </button>
      <button mat-flat-button (click)="select(apps.worker)" [title]="'mm.common.applications.worker' | translate">
        <mat-icon svgIcon="mm-service-worker"></mat-icon>
      </button>
    </div>
  </mm-tooltip-card>
</div>
<ng-template #loadingTmp>
  <mm-loading></mm-loading>
</ng-template>
