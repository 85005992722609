import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { of } from 'rxjs';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

/**
 * Custom icons added to the material icons' registry (placed in src/assets/img/icons)
 * The icons must be in SVG format and the key is always prefixed by "mm-".
 * All icons are sorted by alphabetical order!
 */
const CUSTOM_ICONS = {
  'mm-check-green': 'check-green.svg',
  'mm-fluff': 'fluff.svg',
  'mm-hexagon': 'hexagon.svg',
  'mm-info-circle': 'info-circle.svg',
  'mm-lock': 'lock.svg',
  'mm-light-bulb': 'light-bulb.svg',
  'mm-long-arrow-right-pink': 'long-arrow-right-pink.svg',
  'mm-service-talents': 'service-talents.svg',
  'mm-service-worker': 'service-worker.svg',
  'mm-service-accounting': 'service-accounting.svg',
  'mm-service-social': 'service-social.svg',
  'mm-shield': 'shield.svg',
  'mm-danger': 'danger.svg',
  'mm-danger-plain': 'danger-plain.svg',
};

const MATERIAL = [
  MatProgressSpinnerModule,
  MatInputModule,
  MatFormFieldModule,
  MatIconModule,
  MatRadioModule,
  MatButtonModule,
  MatExpansionModule,
  MatTooltipModule,
  MatMenuModule,
];

/**
 * Imports Material modules
 * Add Custom Icons to MatIconsRegistry
 * Configuration for Date Format
 */
@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, ...MATERIAL],
  exports: MATERIAL,
  providers: [{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }],
})
export class MaterialModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    for (const [name, path] of Object.entries(CUSTOM_ICONS)) {
      this.matIconRegistry.addSvgIcon(
        name,
        this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/icons/' + path),
      );
    }
  }
}

@NgModule({
  declarations: [],
  imports: [CommonModule, ...MATERIAL],
  exports: MATERIAL,
  providers: [{ provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }],
})
export class MaterialTestingModule {
  constructor(private matIconRegistry: MatIconRegistry) {
    this.matIconRegistry.addSvgIcon = () => this.matIconRegistry;
    this.matIconRegistry.getDefaultFontSetClass = () => ['material-icons'];
    this.matIconRegistry.getSvgIconFromUrl = () => of(MaterialTestingModule.generateEmptySvg());
    this.matIconRegistry.getNamedSvgIcon = () => of(MaterialTestingModule.generateEmptySvg());
  }

  private static generateEmptySvg(): SVGElement {
    const emptySvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    emptySvg.classList.add('fake-testing-svg');
    // Emulate real icon characteristics from `MatIconRegistry` so size remains consistent in tests.
    emptySvg.setAttribute('fit', '');
    emptySvg.setAttribute('height', '100%');
    emptySvg.setAttribute('width', '100%');
    emptySvg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
    emptySvg.setAttribute('focusable', 'false');
    return emptySvg;
  }
}
