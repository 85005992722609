import { AfterViewInit, Component, Input, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { InputError } from '../../models/input-error';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

@Component({
  selector: 'mm-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
})
export class OtpInputComponent implements ControlValueAccessor, AfterViewInit {
  /**
   * Length of the OTP that the user will have to type in
   */
  @Input() otpLength = 6;

  /**
   * If True, the input will be focused when the component is initialized
   */
  @Input() autofocus = false;

  /**
   * ID of the input, useful to attach the label to the input
   */
  @Input() id: string;

  /**
   * Name of the input
   */
  @Input() name: string;

  /**
   * The initial value of the input
   */
  @Input() value = '';

  /**
   * Errors available for this input, see {@link InputError} for usage.
   * Only work with Angular Forms.
   */
  @Input() errors: InputError[];

  /**
   * Tell if this input is disabled
   */
  @Input() disabled: boolean;

  /**
   * Retrieve the child Material Input see {@link MatInput}
   */
  @ViewChild(MatInput) matInput: MatInput;

  /**
   * Store the on change function triggered when the value change
   */
  private onChange: (value: string) => void = () => {
    // This is intentional to be empty at init
  };

  /**
   * Store the on touched function triggered when the input is touched
   */
  private onTouched = () => {
    // This is intentional to be empty at init
  };

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * Set to the child matInput the provided ngControl
   */
  ngAfterViewInit() {
    // In order to avoid angular check issue, we need to move this task outside check process with a setTimeout
    // See https://angular.io/guide/component-interaction#parent-calls-an-viewchild
    setTimeout(() => {
      this.matInput.ngControl = this.ngControl;
    });

    // If autofocus is True, focus the last input
    if (this.autofocus) {
      // We use a setTimeout to prevent an Angular ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => this.focus());
    }
  }

  get placeholder(): string {
    return '_'.repeat(this.otpLength);
  }

  /**
   * Implement required method to use Angular Forms see {@link ControlValueAccessor.registerOnChange}
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Implement required method to use Angular Forms see {@link ControlValueAccessor.registerOnTouched}
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Implement required method to use Angular Forms see {@link ControlValueAccessor.setDisabledState}
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Implement required method to use Angular Forms see {@link ControlValueAccessor.writeValue}
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Retrieve the control if present on this component
   */
  getControl(): AbstractControl | null {
    return this.ngControl?.control;
  }

  /**
   * Update the current value with string entered in the input element
   */
  input(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.split('').filter(this.isInputValueValid).join('');
    this.value = inputElement.value;
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  /**
   * Check if an input value is valid
   *
   * @param value Input value to check
   * @returns True if the value is not empty and only has digits, False otherwise
   */
  private isInputValueValid(value?: string | null): boolean {
    if (value == null || value.length === 0) {
      return false;
    }

    return /^\d+$/.test(value.toString());
  }

  /**
   * On input focus, mark the component as touched
   */
  onFocus(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  /**
   * Focuses the input
   */
  focus() {
    this.matInput.focus();
  }
}
