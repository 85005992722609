import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule as DefaultTranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../../environments/environment';
import { LOCATION_INITIALIZED } from '@angular/common';
import { Language } from './models/environment';
import { LoggerService } from '../core/services/logger.service';

export const defaultLang = environment.defaultLanguage;

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Wait translation file are loaded before rendering the application (for routing and instant translation)
export function TranslateInitializerFactory(translate: TranslateService, logger: LoggerService, injector: Injector) {
  return () =>
    new Promise<void>((resolve: () => void, reject: (reason: any) => void) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        // Set default language for fallback
        translate.setDefaultLang(defaultLang);
        logger.debug('Default language:', defaultLang);

        // Get language provided by browser
        const browserLang = translate.getBrowserLang();
        logger.debug('Browser detected language:', browserLang);

        // Get language asked by query params
        let queryParamLang: string;
        try {
          queryParamLang = new URL(window.location.href).searchParams.get(environment?.queryParamsKeys?.language);
          logger.debug('Query params language:', queryParamLang);
        } catch (err) {
          logger.warn('Unable to retrieve a valid language from query params, see error:', err);
        }

        // Select the language
        let usedLanguage: string = defaultLang;
        if (queryParamLang && Language?.[queryParamLang]) {
          usedLanguage = queryParamLang;
        } else if (browserLang && Language?.[browserLang]) {
          usedLanguage = browserLang;
        }
        logger.debug('Language used:', usedLanguage);

        // Set language
        return translate.use(usedLanguage).subscribe(
          () => {
            // This is intentional to be empty, only for trigger the translation and handle error if needed
          },
          err => {
            logger.error(`Problem with '${usedLanguage}' language initialization:`, err);
            reject(err);
          },
          () => {
            resolve();
          },
        );
      });
    });
}

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    DefaultTranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [DefaultTranslateModule],
})
export class TranslateModule {
  static forRoot(): ModuleWithProviders<TranslateModule> {
    return DefaultTranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    });
  }
}

@NgModule({
  imports: [DefaultTranslateModule.forRoot()],
  exports: [DefaultTranslateModule],
})
export class TranslateTestingModule {}
