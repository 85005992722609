import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageLoginComponent } from './pages/login/page-login.component';
import { PageSelectAppComponent } from './pages/select-app/page-select-app.component';
import {
  AppRoutingChangePassword,
  AppRoutingNewPassword,
  AppRoutingLogin,
  AppRoutingPasswordChanged,
  AppRoutingPasswordReset,
  AppRoutingSelect,
} from './app-routing.model';
import { PagePasswordResetComponent } from './pages/password-reset/page-password-reset.component';
import { PageChangePasswordComponent } from './pages/change-password/page-change-password.component';
import { PageNewPasswordComponent } from './pages/new-password/page-new-password.component';
import { PagePasswordChangedComponent } from './pages/password-changed/page-password-changed.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: AppRoutingLogin,
    pathMatch: 'full',
  },
  {
    path: AppRoutingLogin,
    component: PageLoginComponent,
  },
  {
    path: AppRoutingPasswordReset,
    component: PagePasswordResetComponent,
  },
  {
    path: AppRoutingChangePassword,
    component: PageChangePasswordComponent,
  },
  {
    path: AppRoutingNewPassword,
    component: PageNewPasswordComponent,
  },
  {
    path: AppRoutingPasswordChanged,
    component: PagePasswordChangedComponent,
  },
  {
    path: AppRoutingSelect,
    component: PageSelectAppComponent,
  },
  {
    path: '**',
    redirectTo: AppRoutingLogin,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
