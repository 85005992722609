import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from './environments/environment';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import pkg from '../../../package.json';
import project from '../project.json';

if (environment.production) {
  enableProdMode();
}

if (['prod'].includes(environment.envName)) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.envName,
    release: `${project.name}@${pkg.version}`,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
