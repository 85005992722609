import { Component, Input } from '@angular/core';

export enum THEMES {
  pink = 'pink',
  blue = 'blue',
  red = 'red',
  green = 'green',
}

@Component({
  selector: 'mm-tooltip-card',
  templateUrl: './tooltip-card.component.html',
  styleUrls: ['./tooltip-card.component.scss'],
})
export class TooltipCardComponent {
  @Input() theme = THEMES.pink;
  @Input() backgroundColor: string;
  @Input() linkText: string;
  @Input() linkUrl: string;
  @Input() linkColor: string;
  @Input() titleColor: string;
  @Input() iconFluff = true;
  @Input() icon = 'mm-light-bulb';
  @Input() iconLink = 'mm-long-arrow-right-pink';
  @Input() title: string;
}
