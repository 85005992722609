import { ClassProvider, InjectionToken, Type } from '@angular/core';

export const RIGHTS_SERVICE = new InjectionToken<unknown>('movinmotion-frontend.rights-service');

export function getRightsProvider<T>(value: Type<T>): ClassProvider {
  return {
    provide: RIGHTS_SERVICE,
    useClass: value,
  };
}
