import { ClassProvider, InjectionToken, Type } from '@angular/core';
import { AuthenticationProvider } from '../interfaces/authentication-provider';

export const AUTH_PROVIDER = new InjectionToken<AuthenticationProvider>('movinmotion-frontend.auth-provider');

export function getAuthProvider<T>(value: Type<T>): ClassProvider {
  return {
    provide: AUTH_PROVIDER,
    useClass: value,
  };
}
