import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';
import moment, { Moment } from 'moment';
import format from 'date-fns/format';

export const LOCAL_DATES = new HttpContextToken<string[] | null>(() => null);

interface ReqBody {
  [key: string]: unknown;
}

@Injectable()
export class DateUniformizationInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<ReqBody>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const localDates = request.context.get(LOCAL_DATES);

    if (Array.isArray(localDates) && request?.body && Object.keys(request?.body)?.length > 0) {
      this.iterate(request.body, request.context.get(LOCAL_DATES) as string[]);
    }

    return next.handle(request);
  }

  iterate(obj: ReqBody, localDates: string[]): void {
    if (obj && Object.keys(obj)?.length > 0) {
      Object.keys(obj).forEach(key => {
        if (obj[key] instanceof Date || moment.isDate(obj[key]) || moment.isMoment(obj[key])) {
          const currentDate: Date = obj[key] instanceof Date ? (obj[key] as Date) : (obj[key] as Moment).toDate();

          obj[key] = new Date(
            Date.UTC(currentDate.getUTCFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0),
          );

          if (localDates.length === 0 || localDates.includes(key)) {
            obj[key] = format(obj[key] as Date, 'yyyy-MM-dd');
          }
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.iterate(obj[key] as ReqBody, localDates);
        }
      });
    }
  }
}
