export const invalidNafCodeOption = 'invalid-naf-code-option';

export interface NafCodeOption {
  id: string;
  name: string;
  [key: string]: unknown;
}

export const NafCodes: { [code: string]: string } = {
  '5911A': 'Production de films et de programmes pour la télévision',
  '5911B': 'Production de films institutionnels et publicitaires',
  '5911C': 'Production de films pour le cinéma',
  '5912Z': 'Post-production de films cinématographiques, de vidéo et de programmes de télévision',
  '5920Z': 'Enregistrement sonore et édition musicale',
  '6010Z': 'Radiodiffusion',
  '6020A': 'Edition de chaînes généralistes',
  '6020B': 'Edition de chaînes thématiques',
  '6391Z': 'Activités des agences de presse',
  '6420Z': 'Activités des sociétés holding',
  '7022Z': 'Conseil pour les affaires et autres conseils de gestion',
  '7112B': 'Ingénierie, études techniques',
  '8010Z': 'Activités de sécurité privée',
  '8552Z': 'Enseignement culturel',
  '9001Z': 'Arts du spectacle vivant',
  '9002Z': 'Activités de soutien au spectacle vivant',
  '9003B': 'Autre création artistique',
  '9004Z': 'Gestion de salles de spectacles',
  '9321Z': "Activités des parcs d'attractions et parcs à thèmes",
  '9329Z': 'Autres activités récréatives et de loisirs',
  '9499Z': 'Autres organisations fonctionnant par adhésion volontaire',
  '0': 'Autre',
};

export const NafCodeOptions: NafCodeOption[] = Object.entries(NafCodes)
  .map(([id, name]) => ({ id, name }))
  .sort((a, b) => a.id.localeCompare(b.id));
