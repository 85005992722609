<div class="mm-input-container" [ngClass]="{ small: small, disabled: disabled }">
  <label class="mm-label" [for]="id">{{ label }}</label>
  <mat-form-field class="mm-input-form" appearance="outline">
    <mat-icon *ngIf="prefixIcon" matPrefix [svgIcon]="prefixIcon"></mat-icon>
    <input
      matInput
      class="mm-input-field"
      [placeholder]="placeholder"
      [type]="hide ? 'password' : type"
      [id]="id"
      [name]="name"
      [value]="value"
      [disabled]="disabled"
      (input)="input($event)"
      [attr.maxlength]="maxLength"
      (blur)="blur($event)"
    />
    <button type="button" *ngIf="showBtn" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-pressed]="hide">
      <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-icon
      *ngIf="(suffixIcon || disabled) && !isLoading()"
      matSuffix
      [svgIcon]="disabled ? disabledIcon : suffixIcon"
    ></mat-icon>
    <span *ngIf="(!disabled && suffixText) || isLoading()" matSuffix class="mm-input-suffix">
      {{ disabled ? '' : suffixText }} <mat-spinner [diameter]="25" *ngIf="isLoading()"></mat-spinner>
    </span>
  </mat-form-field>

  <ng-container *ngFor="let error of errors">
    <mat-error
      class="mm-input-error"
      *ngIf="error.isError(getControl()) && (getControl()?.dirty || getControl()?.touched)"
    >
      <mat-icon class="mm-input-error-icon" svgIcon="mm-hexagon"></mat-icon>{{ error.text$ | async }}
    </mat-error>
  </ng-container>
  <div class="panel-info">
    <ng-content></ng-content>
  </div>
  <ng-content select="[CustomInputComponentWarnMessage]"></ng-content>
</div>
