import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
  OnInit,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { InputError } from '../../models/input-error';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { FormModelStatus } from '../../models/form';

/**
 * Simple input designed for Movinmotion.
 * Use underlying Material Input {@see MatInput}.
 * For example the Custom Input Story for usage:
 *  - Disabled {@see CustomInputDisabled}
 *  - Number type {@see CustomInputNumber}
 *  - Text type {@see CustomInputText}
 *  - Email type {@see CustomInputEmail}
 *  - Password type {@see CustomInputPassword}
 *  - Password and disabled {@see CustomInputPasswordDisabled}
 *  - Text type without placeholder {@see CustomInputWithoutPlaceholder}
 *  - Design small {@see CustomInputSmall}
 *  - Using Angular Forms {@see CustomInputWithFormControlName}
 */
@Component({
  selector: 'mm-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
})
export class CustomInputComponent implements ControlValueAccessor, AfterViewInit, OnInit {
  showBtn = false;

  /**
   * Hide password input content
   */
  @Input() hide = false;

  /**
   * ID of the input, useful to attach the label to the input
   */
  @Input() id: string;

  /**
   * Name of the input
   */
  @Input() name: string;

  /**
   * The initial value of the input
   */
  @Input() value = '';

  /**
   * The type of the input, default to 'text'
   */
  @Input() type = 'text';

  /**
   * The label displayed on top of the input
   */
  @Input() label: string;

  /**
   * The input placeholder
   */
  @Input() placeholder: string;

  /**
   * Text display as suffix in the input (useful to specify an unity for example)
   */
  @Input() suffixText: string;

  /**
   * Errors available for this input, {@see InputError} for usage.
   * Only work with Angular Forms.
   */
  @Input() errors: InputError[];

  /**
   * Tell if this input is disabled
   */
  @Input() disabled: boolean;

  /**
   * Design option, to use the small input
   */
  @Input() small = false;

  /**
   * Design option, used icon in prefix for the input
   */
  @Input() prefixIcon: string;

  /**
   * Design option, used icon in suffix for the input
   */
  @Input() suffixIcon: string;

  /**
   * Design option, used icon in suffix is input is disabled
   */
  @Input() disabledIcon = 'mm-lock';

  /**
   * Design option, to use to display a loader in suffix
   */
  @Input() loading = false;

  /**
   * Design option, to use for input maxlength
   */
  @Input() maxLength?: number;

  /**
   * Retrieve the child Material Input {@see MatInput}
   */
  @ViewChild(MatInput) matInput: MatInput;

  /**
   * Handle the blur event of the input
   */
  @Output() inputBlur = new EventEmitter<any>();

  /**
   * Store the on change function triggered when the value change
   */
  private onChange: (value: any) => void = () => {
    // This is intentional to be empty at init
  };

  /**
   * Store the on touched function triggered when the input is touched
   */
  private onTouched = () => {
    // This is intentional to be empty at init
  };

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * Set to the child matInput the provided ngControl
   */
  ngAfterViewInit() {
    // In order to avoid angular check issue, we need to move this task outside check process with a setTimeout
    // See https://angular.io/guide/component-interaction#parent-calls-an-viewchild
    setTimeout(() => {
      this.matInput.ngControl = this.ngControl;
    });
  }

  ngOnInit() {
    this.hideInputText();
  }

  /**
   * set type to text if hide is true, in order to hide and show input text
   */
  hideInputText(): void {
    if (this.hide) {
      this.type = 'text';
      this.showBtn = true;
    }
  }

  /**
   * Implement required method to use Angular Forms {@see ControlValueAccessor.registerOnChange}
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Implement required method to use Angular Forms {@see ControlValueAccessor.registerOnTouched}
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Implement required method to use Angular Forms {@see ControlValueAccessor.setDisabledState}
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Implement required method to use Angular Forms {@see ControlValueAccessor.writeValue}
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Retrieve the control if present on this component
   */
  getControl(): AbstractControl | null {
    return this.ngControl?.control;
  }

  /**
   * Update the current value with string entered in the input element
   */
  input(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.value = inputElement.value;
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  /**
   * Blur event triggered on the autocomplete input
   */
  blur(event) {
    if (this.onTouched) {
      this.onTouched();
    }
    this.inputBlur.emit(event);
  }

  /**
   * Tell if the input is in loading state regarding the control (if provided) or the loading input
   */
  isLoading() {
    if (this.ngControl?.status === FormModelStatus.PENDING) {
      return true;
    } else {
      return this.loading;
    }
  }
}
