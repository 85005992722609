export enum MovinmotionAppEnum {
  ADMIN = 'ADMIN',
  WORKER = 'WORKER',
  TALENTS = 'TALENTS',
  TALENT_BO = 'TALENT_BO',
  COMPTA = 'COMPTA',
  INSURANCE = 'INSURANCE',
  SOCIAL = 'SOCIAL',
  CORE = 'CORE',
}
