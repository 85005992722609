<main class="main" [ngClass]="appDisplay.getDisplay$() | async">
  <div class="language-selector" *ngIf="(appDisplay.getDisplay$() | async) === workerApp">
    <button mat-icon-button [matMenuTriggerFor]="languageMenu">
      <mat-icon [title]="'mm.common.language.title' | translate">language</mat-icon>
    </button>
    <mat-menu #languageMenu="matMenu">
      <button mat-menu-item type="button" (click)="selectLanguage(languages.fr)" title="Passer en français">FR</button>
      <button mat-menu-item type="button" (click)="selectLanguage(languages.en)" title="Switch to english">EN</button>
    </mat-menu>
  </div>

  <router-outlet></router-outlet>
</main>
