import { Provider } from '@angular/core';
import { environment as sharedEnvironment, getEnvironmentProvider } from '@movinmotion/conf-shared';
import { environment } from '../../../environments/environment';
import merge from 'ts-deepmerge';

export const mergedEnvironment = merge(sharedEnvironment, environment);

export function provideEnvironment(): Provider {
  return getEnvironmentProvider(mergedEnvironment);
}
