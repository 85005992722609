import { ValidationErrors } from '@angular/forms';

/**
 * In order to avoid using plain text for Angular Form status, use this enum
 * For more details, {@see AbstractControl.status}
 */
export enum FormModelStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED',
}

export const formKeyModel = 'model';
export const formKeyDirty = 'dirty';
export const formKeyStatus = 'status';
export const formKeyErrors = 'errors';

export interface FormModel<T = any> {
  [formKeyModel]?: T;
  [formKeyDirty]?: boolean;
  [formKeyStatus]?: FormModelStatus;
  [formKeyErrors]?: ValidationErrors | null;
}
