import { inject, Injectable, OnDestroy } from '@angular/core';
import { MixpanelService } from './mixpanel/mixpanel.service';
import { APP_CONFIG, ENVIRONMENT_CONFIG, MovinmotionAppEnum } from '@movinmotion/conf-shared';
import { filter, interval, Observable, of, pairwise, Subject, switchMap, takeUntil } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

interface AxeptioSdk {
  on: (element: string, cb: (...args: never[]) => void) => void;
  userPreferencesManager: {
    readChoicesFromCookies: (element: string) => {
      $$date: string;
    };
    setChoices: (choices: Choices) => void;
  };
  hasAcceptedVendor: (vendor: string) => boolean | undefined;
}

interface Choices {
  mixpanel: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AxeptioService implements OnDestroy {
  choices$ = new Subject<Choices>();

  private userCookiesDuration = 365;
  private mixpanel = inject(MixpanelService);
  private sdk: AxeptioSdk | undefined;
  private cookieService = inject(CookieService);
  private axeptioCookies = ['axeptio_cookies', 'axeptio_all_vendors', 'axeptio_authorized_vendors'];
  private environment = inject(ENVIRONMENT_CONFIG);
  private app = inject(APP_CONFIG);

  private cookieUpdateManually = false;

  private destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  includeAxeptioSnippet(): void {
    if (this.environment.thirdParty?.axeptio) {
      this.clearOldCookies();
      window['axeptioSettings'] = {
        clientId: this.environment.thirdParty.axeptio.clientId,
        cookiesVersion: this.environment.thirdParty.axeptio.cookiesVersion,
        userCookiesDuration: this.userCookiesDuration,
        userCookiesDomain: this.environment.cookieDomain,
      };

      // Pasted from axeptio documentation and tried to prettify it a little bit
      const existingScripts = document.getElementsByTagName('script')[0];
      const axeptioScript = document.createElement('script');
      axeptioScript.async = true;
      axeptioScript.src = '//static.axept.io/sdk.js';
      existingScripts?.parentNode?.insertBefore(axeptioScript, existingScripts);

      window['_axcb'] = window['_axcb'] || [];
      // @ts-expect-error: because of window
      window['_axcb'].push((sdk: AxeptioSdk) => {
        this.sdk = sdk;
        setTimeout(() => {
          this.sdkImpl(sdk);
          // @ts-expect-error: because of window
          window['hideAxeptioButton']();
        }, 500);
      });
    }
  }

  getVendorValue(vendor: string): boolean | undefined {
    return this.sdk?.hasAcceptedVendor(vendor);
  }

  setMixpanelValue(value: boolean): void {
    // @ts-expect-error: because of window
    window['axeptioHandleVendors']({ mixpanel: value });
    this.cookieUpdateManually = true;
  }

  listenForCookieChange$(cookieName = 'axeptio_authorized_vendors'): Observable<[string, string]> {
    return interval(500).pipe(
      switchMap(() => of(this.cookieService.get(cookieName))),
      pairwise(),
      filter(([prev, curr]) => prev !== curr),
      takeUntil(this.destroy$),
    );
  }

  /**
   * When axeptio was first implemented, cookies were created on each app url so any of them didn't know about other app axeptio cookies
   * But for cards
   * <ul>
   *   <li>https://movinmotion.atlassian.net/browse/MOV-12628</li>
   *   <li>https://movinmotion.atlassian.net/browse/MOV-12651</li>
   * </ul>
   * we needed that each app know about others and modified "userCookiesDomain" param in axeptio implementation to use parent domain instead of specific app one.
   * But old cookies still exists for 1 year on client browsers so with this method we delete them to avoid concurrency between those 2 cookies
   * @deprecated on January 2025
   */
  private clearOldCookies(): void {
    if (this.app.appId === MovinmotionAppEnum.TALENTS || this.app.appId === MovinmotionAppEnum.TALENT_BO) {
      const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
      for (const axeptioCookie of this.axeptioCookies) {
        this.cookieService.set(axeptioCookie, '', {
          path: '/',
          expires: expiresDate,
        });
      }
    }
  }

  private sdkImpl(sdk: AxeptioSdk): void {
    sdk.on('cookies:complete', (choices: Choices) => {
      this.updateCookieChoices(choices);
    });
    sdk.on('consent:saved', () => {
      this.cookieUpdateManually = true;
    });

    sdk.on('ready', () => {
      this.listenForCookieChange$().subscribe(() => {
        if (!this.cookieUpdateManually) {
          window.location.reload();
        } else {
          this.cookieUpdateManually = false;
        }
      });
    });

    this.handleCookieExpiration(sdk);
  }

  private updateCookieChoices(choices: Choices): void {
    this.choices$.next(choices);
    if (choices.mixpanel) {
      this.enableMixpanel();
    } else {
      this.disableMixpanel();
    }
  }

  private handleCookieExpiration(sdk: AxeptioSdk): void {
    try {
      const json = sdk.userPreferencesManager.readChoicesFromCookies('axeptio_cookies');
      if (json?.$$date) {
        const acceptedAt = new Date(json.$$date);
        const now = new Date();
        if ((now.getTime() - acceptedAt.getTime()) / (1000 * 60 * 60) > this.userCookiesDuration) {
          // @ts-expect-error: because of window
          window['openAxeptioCookies']();
        }
      }
    } catch (err) {
      console.error('Axeptio could not read previous choices nor parse consent date', err);
    }
  }

  private enableMixpanel(): void {
    this.mixpanel.hasUserOptIn$.next(true);
  }

  private disableMixpanel(): void {
    this.mixpanel.hasUserOptIn$.next(false);
  }
}
