import { UserCredential } from 'firebase/auth';
import { environment } from '../../../environments/environment';

export enum AuthCode {
  success = 'success',
  failure = 'failure',
  unknown = 'unknown',
  codeInvalid = 'codeInvalid',
  tooManyRequest = 'tooManyRequest',
}

export interface AuthResult {
  code: AuthCode;
  userCredential?: UserCredential;
  error?: LoginError;
}

export const queryParamKeyRedirectUrl = environment.queryParamsKeys.redirectUrl;
export const queryParamKeyToken = environment.queryParamsKeys.token;
export const queryParamKeyEntropy = environment.queryParamsKeys.entropy;

export interface LoginResult {
  kind: string;
  registered: boolean;
  localId: string;
  email: string;
  idToken: string;
  refreshToken: string;
  expiresIn: string;
  error: LoginError;
}

export interface LoginError {
  code: number;
  message: string;
  errors: LoginErrorDetails[];
}

export interface LoginErrorDetails {
  message: string;
  reason: string;
  domain: string;
}
