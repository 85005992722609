import { HttpLoader } from './http-loader';
import { TranslateConfig } from './translate-config';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';

export const translateProvider = [
  provideTransloco({
    config: TranslateConfig,
    loader: HttpLoader,
  }),
  provideTranslocoMessageformat(),
];
