<div class="tooltip-container" [ngStyle]="{ 'background-color': backgroundColor }" [ngClass]="theme">
  <div class="tooltip-icon-container" *ngIf="icon" [ngStyle]="{ 'border-color': backgroundColor }">
    <mat-icon class="tooltip-icon" [svgIcon]="icon"></mat-icon>
    <mat-icon class="tooltip-icon-fluff" *ngIf="iconFluff" svgIcon="mm-fluff"></mat-icon>
  </div>
  <h3 class="tooltip-title mat-small-4" *ngIf="title" [ngStyle]="{ color: titleColor }">
    {{ title }}
  </h3>
  <div class="mat-caption">
    <ng-content></ng-content>
  </div>
  <a
    class="tooltip-link mat-small-2"
    fxLayout="row"
    fxLayoutAlign="start center"
    *ngIf="linkUrl"
    [routerLink]="linkUrl"
    [ngStyle]="{ color: linkColor }"
  >
    {{ linkText ? linkText : linkUrl }} <mat-icon [svgIcon]="iconLink"></mat-icon>
  </a>
</div>
